import { ChartOptions } from 'chart.js';

import { CosmosDarkColors } from '../../../utils/colors';

/**
 * DoughnutData
 *
 * The structure of the data to be provided to the Doughnut.vue component.
 * Used to generate the ChartOptions chart.js options parameter.
 *
 * - color: The color of the segment.
 * - label: The label for the segment; displayed in the legend and on hover.
 * - value: The value of the segment; used to calculate segment size.
 */
export interface DoughnutData {
  readonly color: string;
  readonly label: string;
  readonly value: number;
}

/**
 * Doughnut Center Content Plugin Options
 *
 * Custom plugin for the doughnut component to provide content to be
 * displayed in the center of the chart.
 *
 *  - text: the large text, displayed at the bottom
 *  - subtext: the small text, displayed at the top
 */
export interface DoughnutCenterContentPluginOptions {
  text?: string;
  subtext?: string;
}

/**
 * Doughnut HTML Legend Plugin Options
 *
 * Custom plugin for the doughnut component to create a custom HTML
 * legend displayed below the chart.
 *
 *  - itemSubtextTranslationKey:
 *      translation key for the text displayed under each legend item
 *      (is a translation key to support pluralization)
 */
export interface DoughnutHtmlLegendPluginOptions {
  itemSubtextTranslationKey?: string;
}

/**
 * DoughnutOptions
 *
 * Provides the structure for the custom options available to the doughnut component.
 */
export type DoughnutOptions = ChartOptions & {
  plugins?: {
    centerContent?: DoughnutCenterContentPluginOptions;
    htmlLegend?: DoughnutHtmlLegendPluginOptions;
  };
};

export interface DoughnutSimpleSegment {
  value: number;
  color: string;
  type: string;
}

export interface DoughnutSimpleSegmentForSVG extends DoughnutSimpleSegment {
  dashOffset: number;
  degrees: number;
}

/**
 * Default Doughnut Dataset Config
 *
 * The default values to be applied to a doughnut dataset.
 * Provides a way to keep things consistent across custom doughnut charts.
 */
export const DEFAULT_DOUGHNUT_DATASET_CONFIG = {
  borderWidth: 5,
  borderAlign: 'center',
  hoverBorderColor: CosmosDarkColors.Storm3,
  borderColor: CosmosDarkColors.Storm3,
};

/**
 * Empty Doughnut Dataset
 *
 * The values for an "empty" dataset.
 * Creates a single grey 100% segment around the whole doughnut.
 */
export const EMPTY_DOUGHNUT_DATASET = {
  backgroundColor: [CosmosDarkColors.ContentDark],
  borderWidth: 0,
  data: [1],
};
